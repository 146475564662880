
@font-face {
  font-family: 'Digital-Numbers';
    src:  url('/fonts/Digital-Numbers.ttf.woff') format('woff'),
    url('/fonts/Digital-Numbers.ttf.svg#Digital-Numbers') format('svg'),
    url('/fonts/Digital-Numbers.ttf.eot'),
    url('/fonts/Digital-Numbers.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}

:root{
  --gray: rgba(28,28,28);
}

.number {
  font-family: 'Digital-Numbers';   
display: inline-flex;
align-items: flex-start;
font-size: 16px;
}
.number .dec{ font-size: 75%;  opacity: 0.45;}



html,body,#root,.App{
  min-height: 100%;
  height: 100%;
  font-size: 16px;
  
}
body{
  color:white;
  background: rgb(74,74,74);
  background: radial-gradient(circle, rgba(74,74,74,1) 0%, rgba(0,14,17,1) 100%, rgba(0,212,255,1) 100%);
}


table{
  background-color: var(--gray);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px -4px #9f9c9c;
}
table tbody{ border: 1px solid black; }
table td{ 
  color:white !important;
  border-bottom: 1px solid rgb(53 52 52) !important;
}

.App {
  text-align: center;
  padding-inline: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.big{ display: flex; align-items:start; font-size: 6.5rem; } 

.big .int{ font-size: 6.5rem; }  
.negative{color:#ff6464;}
.positive{color:#d7ffd7;}

label{
  font-size: .675rem;
  text-transform: uppercase;
  font-weight: bold;
}
.well{ 
  background: var(--gray); 
  border: 1px solid #000; 
  padding: 15px;
  justify-content: space-between;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px -4px #9f9c9c;
}
.well .right{
  min-width: 160px;
  gap: .35rem;
}
.well .right > div{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.positions{ margin-top: 20px;}
.positions > div{
  padding: 5px;
  border-bottom: 1px dotted #ccc;
  align-items: center;

  flex-wrap: nowrap;
  gap: 1rem;
  justify-content: space-between;
} 
.positions > div:last-child{ border-bottom: none;}
.positions > div label{ font-size: .8rem; }

.positions > div > span{ display: flex; align-items: center; gap:3px}